import React, { useRef, useEffect, useState } from 'react'

import styled, {
  withTheme,
  keyframes,
  css,
} from '../../context/theme-provider/styled-components'

const loopingPercent = 10

const loopingAnimation = p => keyframes`
  0% { stroke-dashoffset: ${p.totalLength}; opacity:1}
  10%   { opacity:1}
  20%   { opacity:0.2}
  30%   { opacity:1}
  40%   { opacity:0.2}
  50%   { opacity:1}
  60%   { opacity:0.2}
  70%   { opacity:0}
  75%   { opacity:0}
  80%   { opacity:0}
  85%   { opacity:0.6}
  90%   { opacity:1}
  100%   { stroke-dashoffset: 0; opacity:1}
`
const animation = () =>
  css`
    ${loopingAnimation} 1.2s linear infinite;
  `

const AnimationWrapper = withTheme(styled.svg<{ totalLength: number }>`
  width: 100px;
  height: 100px;
  max-width: 20em;
  margin: 0 auto;

  [id='master'],
  [id='background'] {
    stroke-width: 10;
    stroke-linecap: round; // round|butt
  }

  [id='background'] {
    stroke: url(#Gradient_1);
    opacity: 0.3;
  }

  [id='master'] {
    // Edge don't like calc with css variables, so use Scss variables instead for calculation
    stroke: url(#Gradient_1);
    stroke-dasharray: ${p => (loopingPercent * p.totalLength) / 100}px,
      ${p => p.totalLength - (loopingPercent * p.totalLength) / 100}px;
    animation: ${animation};
  }
`)

const Wrapper = styled.div`
  background: ${p => p.theme.appBackground};
  color: ${p => p.theme.naviLinkMarked};
  display: flex;
  position: fixed;
  justify-content: center;
  flex-direction: column;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
`

const LoadingText = styled.span`
  margin: 0 auto;
  color: ${p => p.theme.appSubTitle};
`

export const AnimatedLoading = ({
  theme,
  loadingText = '',
}: {
  theme: any
  loadingText: string
}) => {
  const refPath = useRef<SVGPathElement>(null)
  const [totalLength, setPathLength] = useState<number>(0)
  useEffect(() => {
    const totalPathLength =
      (refPath.current && refPath.current.getTotalLength()) || 0
    setPathLength(totalPathLength)
  }, [refPath])
  return (
    <Wrapper>
      <svg>
        <defs>
          <symbol id="loopingPath" viewBox="0 0 100 100">
            <linearGradient id="Gradient_1">
              <stop offset="0" stopColor={theme.appBrandGradient2} />
              <stop offset="1" stopColor={theme.appBrandGradient1} />
            </linearGradient>

            <g id="Ebene_1">
              <path
                ref={refPath}
                d="M92.108,78.332 C87.893,84.738 76.744,82.64 65.88,77.644 C55.716,72.97 47.705,64.473 44.135,57.911 C40.144,50.573 38.998,42.214 38.372,37.674 C35.516,16.983 45.103,9.405 50.5,9.5 C57.625,9.625 61.959,16.673 63,26 C63.529,30.734 63.651,42.849 56.698,55.369 C53.536,61.064 49.899,66.321 46.367,69.672 C42.833,73.023 38.07,76.448 33.959,78.609 C24.934,83.354 18.7,83.306 18.282,83.331 C9.802,83.84 5.106,76.501 10.27,66.955 C13.556,60.879 20.036,57.089 24.151,54.831 C28.265,52.572 32.474,50.853 36.871,49.686 C41.267,48.52 46.847,47.697 51.702,47.967 C68.446,48.894 75.855,53.784 79.17,56.218 C96.625,69.036 92.108,78.332 92.108,78.332 z"
                fill="none"
                strokeMiterlimit="4"
              />
            </g>
          </symbol>
        </defs>
      </svg>
      <AnimationWrapper totalLength={totalLength}>
        <use id="background" xlinkHref="#loopingPath" />
        <use id="master" xlinkHref="#loopingPath" />
      </AnimationWrapper>
      {loadingText && <LoadingText>{loadingText}</LoadingText>}
    </Wrapper>
  )
}
export default withTheme(AnimatedLoading)
