// tslint:disable-next-line: object-literal-sort-keys
import { BaseThemedCssFunction, css } from 'styled-components'

export const maxSizes = {
  screenXsMax: 767 /* Very small screens (i.e. smartphones) */,
  // tslint:disable-next-line:object-literal-sort-keys
  screenSmMax: 991,
  screenMdMax: 1199,
  screenLgMax: 1439,
}

export const minSizes = {
  screenXsMin: 480 /* Very small screens (i.e. smartphones) */,
  // tslint:disable-next-line:object-literal-sort-keys
  screenSmMin: 768 /* Small screens (i.e. tablets) */,
  screenMdMin: 992 /* Medium screens (i.e. laptops) */,
  screenLgMin: 1200 /* Large screens (i.e. desktops) */,
  screenXlMin: 1440 /* Very Large screens*/,
}

export const sizes = {
  ...maxSizes,
  ...minSizes,
}

export const mediaQueries = (
  // tslint:disable-next-line: no-shadowed-variable
  sizes: { [key: string]: number },
  sizeCondition: string
) => {
  return Object.keys(sizes).reduce(
    (
      acc: {
        [key: string]: BaseThemedCssFunction<any>
      },
      label
    ) => {
      acc[label] = (...args: any) => css`
        @media (${sizeCondition}: ${sizes[label] / 16}em) {
          // @ts-ignore
          ${css(...args)}
        }
      `
      return acc
    },
    {}
  )
}

/**
 * Media query templates
 * @see https://www.styled-components.com/docs/advanced#media-templates
 */
const media: { [key: string]: BaseThemedCssFunction<any> } = {
  ...mediaQueries(maxSizes, 'max-width'),
  ...mediaQueries(minSizes, 'min-width'),
}

export default media
