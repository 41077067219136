import React, { Suspense, useContext, useEffect } from 'react'
import { Router, View, useLoadingRoute, NotFoundBoundary } from 'react-navi'
import CookieNotice from '../CookieNotice'
import themes from '../../context/theme-provider/themes'
import app from '../../firebase'
import routes from '../../routes'
import '../../css/fonts.css'

// context
import { UserContext } from '../../context/user-indentity-provider'
import { ThemeNameContext } from '../../context/theme-provider/theme-name-provider'

// componennt
import { ThemeProvider } from '../../context/theme-provider/styled-components'
import { GlobalAppWrapper } from '../../container/struct_global'
import LoadingScreen from '../LoadingScreen'

const NotFound = () => {
  return (
    <div className="Layout-error">
      <h2>404 Not Found</h2>
      <p>Perhaps the page has gone for a long walk?</p>
    </div>
  )
}

const Layout = ({ children }) => {
  let loadingRoute = useLoadingRoute()
  if (!!loadingRoute) {
    return <LoadingScreen loadingText="...init" />
  }
  return (
    <NotFoundBoundary render={() => <NotFound />}>{children}</NotFoundBoundary>
  )
}

export default () => {
  const { themeName } = useContext(ThemeNameContext)
  const {
    user,
    userLoading,
    loginUser,
    logoutUser,
    logoutRedirectUrl,
    setUserLoading,
    setLogoutRedirectUrl,
  } = useContext(UserContext)

  useEffect(() => {
    app.auth().onAuthStateChanged(user => {
      if (user) {
        loginUser(user)
        setUserLoading(false)
      } else {
        setUserLoading(false)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ThemeProvider theme={themes[themeName]}>
      <GlobalAppWrapper>
        {userLoading ? (
          <LoadingScreen loadingText="...init" />
        ) : (
          <Router
            routes={routes}
            context={{
              app,
              user,
              logoutUser,
              logoutRedirectUrl,
              setLogoutRedirectUrl,
            }}
          >
            <Layout>
              <Suspense fallback={<LoadingScreen loadingText="Loading" />}>
                <View />
              </Suspense>
            </Layout>
          </Router>
        )}
        <CookieNotice />
      </GlobalAppWrapper>
    </ThemeProvider>
  )
}
